<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" :title="userType.text" size="lg">
        <h4 class="mb-3">Vous pouvez copier/coller ces adresses dans votre client mail préféré :</h4>
        <p>{{ users.map(formatUser).join(', ') }}</p>
        <div class="mt-4 text-center">
            <b-button @click="hideModal">Fermer</b-button>
        </div>
    </b-modal>
</template>

<script>
    import user       from '@/util/user';
    import alert      from '@/util/alert';
    import {apiPath}  from '@/util/http';
    import modalMixin from '@/mixin/modalMixin';

    export default {
        name: "DisplayMailAdressesModal",
        mixins: [modalMixin],
        props: {
            userType: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalRef: 'mailerHelpModal',
            allUsers: []
        }),
        computed: {
            users() {
                let filterFunction;

                switch (this.userType.value) {
                    case 'ROLE_USER':
                        filterFunction = user.isUser;
                        break;
                    case 'ROLE_CLUJI':
                        filterFunction = user.isCluji;
                        break;
                    case 'ROLE_ASSO':
                        filterFunction = user.isAsso;
                        break;
                    case 'ROLE_ANY_ASSO':
                        filterFunction = user.isAnyAsso;
                        break;
                }

                return this.allUsers.filter(filterFunction);
            }
        },
        methods: {
            loadData() {
                alert.loading();
                this.axios.get(apiPath('admin_valid_users_quickview'))
                    .then(response => this.allUsers = response.data)
                    .catch(() => this.$toaster.error('Impossible de charger la liste des utilisateurs'))
                    .finally(alert.stopLoading);
            },
            formatUser(user) {
                return user.prenom + ' ' + user.nom + ' <' + user.email + '>';
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
